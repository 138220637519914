import React from "react";
import { graphql, HeadFC } from "gatsby";
import Layout from "../components/layout";
import { Trans } from "react-i18next";
import Panzerotto from "../templates/panzerotto";
import { headingClass } from "../components/heading";
import Form, { Input, Label, Submit } from "../components/forms";
import SEO from "../seo";
import { HeaderSpacer, InternalHeaderSpacer } from "../components/header";

const FranchisingForm = () => (
  <Form
    className="grid py-32 snap-start"
    emailSubject="Franchising Request"
    formsparkId="autHXWwj"
    botpoisonKey="pk_7520afbc-1a33-4b9e-8382-95f467e10a26"
  >
    <h3 className={`${headingClass} pt-4`}>Contact us</h3>
    <Label htmlFor="city" required>
      City of interest
    </Label>
    <Input
      type="text"
      id="city"
      name="city"
      placeholder="Type your city"
      required
    />
    <Label htmlFor="firstName" required>
      Name
    </Label>
    <Input
      type="text"
      id="firstName"
      name="first_name"
      placeholder="Type your name"
      required
    />
    <Label htmlFor="lastName" required>
      Last Name
    </Label>
    <Input
      type="text"
      id="lastName"
      name="last_name"
      placeholder="Type your last name"
      required
    />
    <Label htmlFor="email" required>
      Email
    </Label>
    <Input
      type="text"
      id="email"
      name="email"
      placeholder="Type your email"
      required
    />
    <Label htmlFor="telephone" required>
      Phone Number
    </Label>
    <Input
      type="text"
      id="telephone"
      name="telephone"
      placeholder="e.g. +31(0)6 01010101"
      required
    />
    <Submit>Submit</Submit>
  </Form>
);

const StoryTell = ({
  title,
  children,
  className = "",
}: {
  title?: string;
  children: string;
  className?: string;
}) => (
  <div
    className={`p-4 bg-white/60 backdrop-brightness-110 backdrop-blur-sm ${className} max-w-screen-lg`}
  >
    {title && (
      <h3 className={`${headingClass} mb-4`}>
        <span className="bg-white/50">
          <Trans>{title}</Trans>
        </span>
      </h3>
    )}
    <p className="text-lg lg:text-xl text-shadow-white">
      <Trans>{children}</Trans>
    </p>
  </div>
);

const Franchising = () => {
  return (
    <Layout className="snap-proximity">
      <div className="grid lg:grid-cols-2 lg:grid-rows-2 grid-rows-4">
        <Panzerotto background="center / cover url(/franchising/0.jpg)">
          <HeaderSpacer />
          {/* prettier-ignore*/}
          <StoryTell title="Build a Prosperous Future">Six days a week, in Rotterdam, Panzerò serves hot and fresh Apulian-style street food accompanied by genuine hospitality. Behind Panzerò and at the heart of the restaurant are the franchise owners or Panzerò partners as we call them. They are fueled by an entrepreneurial spirit for building businesses and communities. Panzerò is searching for passionate food lovers and leaders who want to put down roots in communities big and small to introduce Panzerò to places we’ve never been before.</StoryTell>
        </Panzerotto>

        <Panzerotto
          background="center / cover url(/franchising/1.jpg)"
          className="flex flex-col"
        >
          <InternalHeaderSpacer />
          {/* prettier-ignore*/}
          <StoryTell className="mt-auto pb-20">We are growing rapidly and franchise opportunities are opening up at Panzerò. We are looking for driven entrepreneurs to carry out our mission of building trusted community-based restaurants. Owning a Panzerò franchise can help you achieve your dreams and provide you with an overwhelming sense of accomplishment. There's no better time to own your future! Come be a part of something big and make your mark on a city near you.</StoryTell>
        </Panzerotto>

        <div
          className="snap-start lg:row-start-2 flex flex-col"
          style={{ background: "center / cover url(/franchising/4.jpg)" }}
        >
          {/* prettier-ignore*/}
          <StoryTell className="mt-32">Learn everything you need to know about opening a licensed Panzerò restaurant in your area.</StoryTell>
        </div>
        <div
          className="flex flex-col snap-start"
          style={{ background: "center / cover url(/franchising/2.jpg)" }}
        >
          <div className="mb-64 lg:my-auto">
            {/* prettier-ignore */}
            <StoryTell className="mt-32">If you’re interested in becoming a Panzerò franchisee please join the waitlist here.</StoryTell>
          </div>
        </div>
      </div>
      <FranchisingForm />
    </Layout>
  );
};

export default Franchising;

export const Head: HeadFC = () => (
  <SEO title="Franchising">
    <script src="https://unpkg.com/@botpoison/browser" async />
  </SEO>
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
